<template>
  <b-row>

    <b-col
      md="3"
    >
      <b-card
        :title="form.slug? 'Modifier une marchandise' : 'Ajouter une marchandise' "
      >
        <b-form @submit.prevent="form.slug?editMarchandise():storeMarchandise()">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Categorie de la marchandise"
                label-for="categoryMarchandise"
              >

                <b-form-input
                  id="categoryMarchandise"
                  placeholder="Categorie de la marchandise"
                  readonly="readonly"
                  :value="categoryMarchandise.libelle"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Marchandise"
                label-for="marchandise"
              >
                <b-form-input
                  id="marchandise"
                  v-model="form.name"
                  :formatter="formatter"
                  placeholder="Marchandise"
                />
                <span
                  v-if="errors.name"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.name[0]"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Poids de la marchandise"
                label-for="marchandise"
              >
                <b-form-input
                  id="marchandise"
                  v-model.number="form.poids"
                  type="number"
                  step="any"
                  placeholder="Poids de la marchandise"
                />
                <span
                  v-if="errors.poids"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.poids[0]"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"

                  placeholder="Description"
                  rows="3"
                />
                <span
                  v-if="errors.description"
                  class="text-danger fs-6"
                  style="font-size:11px"
                  v-text="errors.description[0]"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 btn-block"
                size="sm"
                :disabled="marchandiseProcess"
              >
                {{ form.slug? 'Modifier' : 'Ajouter' }}
                <b-spinner
                  v-if="marchandiseProcess"
                  small
                  label="Loading..."
                  variant="light"
                  class="ml-1"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col
      md="9"
    >
      <b-card
        title="Liste des marchandises"
        no-body
      >

        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :busy="loader"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'admin.marchandise'}">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Voir les catégories de marchandise</span>
              </b-dropdown-item>

              <b-dropdown-item @click="getMarchandise(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Modifier la marchandise</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Supprimer la marchandise</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BFormTextarea, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useMarchandises from '@/services/admin/marchandiseService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,

  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      marchandises, getMarchandiseByCategoryMarchandise, categoryMarchandise, createMarchandise, loader, errors, marchandiseProcess, marchandiseSuccess, marchandise, updateMarchandise,
    } = useMarchandises()

    const perPage = 5
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },

      { key: 'name', label: 'Nom', sortable: true },
      { key: 'poids', label: 'Poids', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      name: '',
      description: '',
      categorie_marchandise_id: categoryMarchandise.value.id,
      poids: '',

    })
    /* eslint-disable global-require */
    const items = marchandises
    onMounted(() => {
      getMarchandiseByCategoryMarchandise()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const sortOptions = computed(() => {
      fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    })

    // Reset Form
    const resetForm = () => {
      form.name = null
      form.description = null
      form.slug = null
      form.poids = null
    }

    // Enregistrer une marchandise
    const storeMarchandise = async () => {
      form.categorie_marchandise_id = categoryMarchandise.value.id
      await createMarchandise({ ...form }).then(() => {
        if (marchandiseSuccess.value === true) {
          resetForm()
        }
      })
    }

    // Obtenir une marchandise par son slug
    const getMarchandise = async item => {
      form.name = item.name
      form.description = item.description
      form.slug = item.slug
      form.poids = item.poids
      form.categorie_marchandise_id = categoryMarchandise.value.id
    }

    // Modifier une marchandise
    const editMarchandise = async () => {
      await updateMarchandise(form).then(() => {
        if (marchandiseSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      sortOptions,
      marchandises,
      form,
      loader,
      errors,
      storeMarchandise,
      formatter,
      marchandiseProcess,
      marchandise,
      getMarchandise,
      editMarchandise,
      categoryMarchandise,
    }
  },
}
</script>
